// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//



// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #7C2A53;
$h2-color: #FBBA00;
$h3-bg-color: #7C2A53;
$h4-bg-color: #FBBA00;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Gilroy', Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;

	&:before {
		position: absolute;
		content: "";
		height: 100%;
		width: 100vw;
		border-top: 8px solid #fff;
		left: 0;
		top: 0;
		z-index: 99;
		pointer-events: none;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

.body_5959 {
	position: relative;

	&:before {
		position: absolute;
		content: "";
		height: 100%;
		width: 100vw;
		border-top: 8px solid #fff;
		border-bottom: 8px solid #fff;
		border-left: 15px solid #fff;
		border-right: 15px solid #fff;
		left: 0;
		top: 0;
		z-index: 99;
		pointer-events: none;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}
}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_5959 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_5959 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		background: rgba(132, 46, 89, 0.9);
		background: linear-gradient(90deg, rgba(132, 46, 89, 0.9) 0%, rgba(125, 0, 62, 0.9) 100%);
		height: 78px;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		pointer-events: none;

		@media only screen and (max-width: 990px) {
			display: none;
		}

	}
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	width: 90%;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	position: relative;
	top: 100px;
	left: 0;
	margin: 0;
	padding: 0;

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		width: fit-content;
		margin: 0 auto;
		top: 130px;

	}

	a {
		span {}

		img {

			@media only screen and (max-width: 990px) {
				margin: 0 auto;
			}

			@media only screen and (max-width: 600px) {
				max-width: 150px;
			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 50px;
	top: 18px;
	z-index: 99;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	gap: 15px;

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		top: 360px;
		justify-content: center;
		flex-direction: column;
		gap: 5px;
	}

	@media only screen and (max-width: 600px) {
		top: 320px;
	}

	.social-border {
		border: 1px solid #fff;
		border-radius: 30px;
		padding: 2px 5px;
		display: flex;
		gap: 3px;
	}

	li {
		display: inline-block;
		@include box(38px);
		padding: 0;
		background: none;
		margin: 0;
		transition: 0.3s;

		&:hover {
			rotate: 7deg;
		}

		a {
			display: block;
			@include box(100%);
		}

		#facebook-head {
			@include box(38px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/fb-ic.png) no-repeat center;
		}

		#twitter-head {
			@include box(38px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/tw-ic.png) no-repeat center;
		}

		#instagram-head {
			@include box(38px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/insta-ic.png) no-repeat center;
		}
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(44px);
	position: relative;
	top: 3px;
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

.search {
	height: 44px !important;
	width: 44px !important;
	position: relative;
	top: 3px;
}

a#FS {
	@include box(44px);
	background: url(/i/design/search-ic.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #7C003E;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_5959 #mainholder {
	padding: 0;
	margin: 0;
	position: static;
}

// -----------------------------------//
// HOME BUTTONS
// -----------------------------------//

#home-btns {
	position: absolute;
	bottom: 80px;
	z-index: 800;
	width: 100%;

	@media only screen and (max-width: 990px) {
		position: relative;
		bottom: auto;
	}

	ul {
		display: flex;
		justify-content: center;
		gap: 25px;
		width: 100%;

		@media only screen and (max-width: 1770px) {
			padding: 0 30px;
		}

		@media only screen and (max-width: 990px) {
			gap: 0;
			padding: 0;
			flex-wrap: wrap;
		}

		@media only screen and (max-width: 500px) {
			flex-direction: column;
		}

		li {
			position: relative;
			background: none;
			padding: 0;
			margin: 0;
			width: 322px;
			height: 119px;
			overflow: hidden;
			outline: 6px solid rgba(255, 255, 255, .1);
			transition: 0.3s;
			opacity: 0.88;

			@media only screen and (max-width: 990px) {
				outline: none;
				border-radius: 0 !important;
				opacity: 1;
			}

			@media only screen and (max-width: 500px) {
				width: 100% !important;
				height: 135px;
			}

			&:hover {
				filter: brightness(110%);

				&:before {}

				a {
					span {
						translate: 0 -5px;
					}
				}
			}

			&:before {
				transition: 0.6s;
			}

			&:nth-of-type(1) {
				background: #a7cecb;
				border-radius: 15px 15px 50px 15px;

				@media only screen and (max-width: 990px) {
					width: 50%;
				}


				&:before {
					position: absolute;
					content: "";
					background: url(/i/design/home-btn-photos/1.jpg) left -100px no-repeat;
					height: 100%;
					width: 100%;
					top: 0px;
					left: 0px;
					opacity: 0.3;
					z-index: 0;
					pointer-events: none;
					background-size: 100%;
					mix-blend-mode: luminosity;

					@media only screen and (max-width: 1440px) {
						background: url(/i/design/home-btn-photos/1.jpg) left -70px no-repeat;
						background-size: 100%;
					}

					@media only screen and (max-width: 990px) {
						background: url(/i/design/home-btn-photos/1.jpg) left -115px no-repeat;
						background-size: cover;
					}
				}
			}

			&:nth-of-type(2) {
				background: #6863c4;
				border-radius: 15px 50px 15px 15px;

				@media only screen and (max-width: 990px) {
					width: 50%;
				}

				&:before {
					position: absolute;
					content: "";
					background: url(/i/design/home-btn-photos/2.jpg) center -60px no-repeat;
					height: 100%;
					width: 100%;
					top: 0px;
					left: 0px;
					opacity: 0.3;
					z-index: 0;
					pointer-events: none;
					background-size: 100%;
					mix-blend-mode: luminosity;
				}

			}

			&:nth-of-type(3) {
				background: #7c003e;
				border-radius: 50px 15px 15px 15px;

				@media only screen and (max-width: 990px) {
					width: 50%;
				}

				&:before {
					position: absolute;
					content: "";
					background: url(/i/design/home-btn-photos/3.jpg) left center no-repeat;
					height: 100%;
					width: 100%;
					top: 0px;
					left: 0px;
					opacity: 0.3;
					z-index: 0;
					pointer-events: none;
					background-size: 110%;
					mix-blend-mode: luminosity;
				}
			}

			&:nth-of-type(4) {
				background: #fbba00;
				border-radius: 15px 15px 15px 50px;

				@media only screen and (max-width: 990px) {
					width: 50%;
				}

				&:before {
					position: absolute;
					content: "";
					background: url(/i/design/home-btn-photos/4.jpg) left -60px no-repeat;
					height: 100%;
					width: 100%;
					top: 0px;
					left: 0px;
					opacity: 0.3;
					z-index: 0;
					pointer-events: none;
					background-size: 100%;
					mix-blend-mode: luminosity;

					@media only screen and (max-width: 1440px) {
						background: url(/i/design/home-btn-photos/4.jpg) left -30px no-repeat;
						background-size: 100%;
					}

					@media only screen and (max-width: 1240px) {
						background: url(/i/design/home-btn-photos/4.jpg) left 10px no-repeat;
						background-size: 100%;
					}

					@media only screen and (max-width: 990px) {
						background: url(/i/design/home-btn-photos/4.jpg) left -50px no-repeat;
						background-size: 100%;
					}
				}
			}

			&:nth-of-type(5) {
				background: #4e7558;
				border-radius: 15px 50px 15px 15px;

				@media only screen and (max-width: 990px) {
					width: 100%;
				}

				&:before {
					position: absolute;
					content: "";
					background: url(/i/design/home-btn-photos/5.jpg) left top no-repeat;
					height: 100%;
					width: 100%;
					top: 0px;
					left: 0px;
					opacity: 0.3;
					z-index: 0;
					pointer-events: none;
					background-size: 110%;
					mix-blend-mode: luminosity;

				}
			}

			a {
				display: block;
				height: 100%;

				span {
					position: relative;
					display: block;
					text-align: center;
					color: #fff;
					font-weight: bolder;
					font-size: 29px;
					line-height: 119px;
					z-index: 999;
					text-shadow: 0px 2px 4px rgba(#000, 0.4);
					transition: 0.4s;

					@media only screen and (max-width: 1140px) {
						font-size: 26px;
					}

					@media only screen and (max-width: 500px) {
						line-height: 135px;
						font-size: 28px;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

.Content1Column,
.Content2Column,
.Content3Column {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: bold;
	}
}

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 20px;
	outline: 5px solid rgba(255, 255, 255, .3);

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;
	border-radius: 20px;
	outline: 5px solid rgba(255, 255, 255, .3);

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;
	border-radius: 20px;
	outline: 5px solid rgba(255, 255, 255, .3);

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: linear-gradient(90deg, rgba(132, 46, 89, 0.9) 0%, rgba(125, 0, 62, 0.9) 100%);
	border-radius: 20px 7px 7px 7px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #222;
	padding: 50px 0;
	position: relative;

	.wrapper {
		text-align: left;
		font-size: 18px;
	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

}

h2.footer-heading {
	padding: 0;
	margin: 0;
	color: #fff;
}

p.address {}

p.telephone {}

footer a[href^="mailto:"] {
	display: block;
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.ip-footer {
	background: linear-gradient(90deg, rgba(132, 46, 89, 0.9) 0%, rgba(125, 0, 62, 0.9) 100%);
	bottom: auto !important;
	height: 50px !important;
	line-height: 50px !important;
	position: relative;
}

.sub-footer {
	height: 30px;
	line-height: 30px;
	position: absolute;
	bottom: 20px;
	overflow: hidden;
	@extend .clearfix;
	z-index: 10;
	width: 100%;
	margin: 0 auto;

	@media only screen and (max-width: 990px) {
		background: linear-gradient(90deg, rgba(132, 46, 89, 0.9) 0%, rgba(125, 0, 62, 0.9) 100%);
		height: auto !important;
		bottom: auto !important;
		line-height: 50px !important;
		position: relative;
	}

	.wrapper {
		width: 90%;
	}

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #fff;
	}

	#copyright,
	#credit {
		a {
			color: #fff;
		}
	}

	#copyright {
		float: left;
	}

	#credit {
		float: right;

		img {
			display: inline;
			margin: -7px 0 0 4px;
		}
	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;