// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_5959 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 750px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // top gradient
  &:before {
    @include before(100%, 450px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90%);
    top: 0;
    left: 0;
  }

  // bottom gradient
  &:after {
    @include before(100%, 667px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(#460123, 0.85) 90%);
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;

    animation: zoom 30s ease forwards infinite;

    @keyframes zoom {
      from {
        transform: scale(1)
      }

      to {
        transform: scale(1.1)
      }
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 240px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 47px;
  font-weight: bolder;

  @media only screen and (max-width: 990px) {
    padding: 0 50px;
    font-size: 5.556vw;
    line-height: 1;
    bottom: 130px;
  }

  @media only screen and (max-width: 600px) {
    bottom: 50px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 27px
  }
}